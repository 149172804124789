import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { auth } from './firebase';

// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const app = createApp(App);

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    iconfont: 'mdi',
  }
})

app.config.productionTip = false;

app.use(router);
app.use(vuetify);
auth.onAuthStateChanged(() => {
  app.mount('#app');
});
