<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Modifier un produit</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field v-model="localItem.name" label="Nom" required></v-text-field>
          <v-textarea v-model="localItem.description" label="Description" required></v-textarea>
          <div v-if="isAWine">
            <v-text-field v-model="localItem.verre" label="Verre" required></v-text-field>
            <v-text-field v-model="localItem.demi" label="Demi" required></v-text-field>
            <v-text-field v-model="localItem.bouteille" label="Bouteille" required></v-text-field>
          </div>
          <v-text-field v-else v-model="localItem.price" label="Prix" required></v-text-field>
        </v-form>
      </v-card-text>
      <div v-if="existingImageUrl">
        <v-img :width="300" aspect-ratio="16/9" cover :src="existingImageUrl" alt="Image du produit"
          class="imageProduit" />
      </div>
      <input type="file" @change="previewImage" accept="image/*" class="file-input" />
      <v-checkbox v-if="existingImageUrl || this.imageData" v-model="localItem.photoActive"
        :label="`Activer la photo`"></v-checkbox>
      <v-btn size="small" v-show="existingImageUrl" color="red-darken-3" class="ma-2 white--text" @click="deleteImage">
        Supprimer l'image
      </v-btn>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="orange darken-1" text @click="close">Annuler</v-btn>
        <v-btn color="green darken-1" @click="save" rounded>Valider</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { storage, db } from '@/firebase'
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import { doc, updateDoc } from 'firebase/firestore'

export default {
  props: {
    open: Boolean,
    item: Object,
  },
  watch: {
    open(val) {
      this.dialog = val
      if (val) {
        this.localItem = { ...this.item }
        this.checkExistingImage()
      }
    },
    dialog(val) {
      if (!val) {
        this.$emit('close')
      }
    },
  },
  data() {
    return {
      dialog: this.open,
      localItem: { ...this.item },
      imageData: null,
      loading: false,
      picture: null,
      existingImageUrl: null,
    };
  },
  methods: {
    close() {
      this.dialog = false
    },
    async save() {
      const docRef = doc(db, this.localItem.category, this.localItem.id)
      await updateDoc(docRef, this.localItem)
      if (this.imageData) {
        this.onUpload()
      }
      this.dialog = false
    },
    previewImage(event) {
      this.imageData = event.target.files[0]
    },
    async onUpload() {
      if (!this.imageData) {
        console.log('No file selected for upload')
        return
      }
      this.loading = true
      const storageRef = ref(storage, `${this.localItem.category}/${this.localItem.id}`)

      try {
        const metadata = {
          contentType: this.imageData.type,
        };
        const snapshot = await uploadBytes(storageRef, this.imageData, metadata)
        const url = await getDownloadURL(snapshot.ref)
        this.picture = url
        console.log('File uploaded successfully:', url)
        this.imageData = null
        this.checkExistingImage()
      } catch (e) {
        console.error('Upload error:', e)
      } finally {
        this.loading = false
      }
    },
    async checkExistingImage() {
      const storageRef = ref(storage, `${this.localItem.category}/${this.localItem.id}`)
      try {
        const url = await getDownloadURL(storageRef)
        this.existingImageUrl = url
      } catch (e) {
        this.localItem.photoActive = false
        console.error('Il n\'y a pas d\'image correspondante', e)
        this.existingImageUrl = null
      }
    },
    async deleteImage() {
      const storageRef = ref(storage, `${this.localItem.category}/${this.localItem.id}`)
      try {
        await deleteObject(storageRef)
        console.log('Image deleted successfully')
        this.existingImageUrl = null
        this.checkExistingImage()
      } catch (e) {
        console.error('Error deleting image:', e)
      }
    },
  },
  computed: {
    isAWine() {
      return this.localItem.category === 'vinBlanc' || this.localItem.category === 'vinRouge' || this.localItem.category === 'vinRose'
    },
  },
};
</script>

<style scoped>
input {
  margin: auto;
}

.imageProduit {
  margin: auto;
  margin-bottom: 10px;
  ;
}
</style>