<template>
  <v-data-table style="margin-top: 100px" :group-by="groupBy" :headers="headers" density="compact" :items="allMenuItems"
    item-value="name" items-per-page="-1" hide-default-footer>
    <template v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen }">
      <tr>
        <td :colspan="columns.length" class="group-header">
          <VBtn :icon="isGroupOpen(item) ? '$expand' : '$next'" size="small" variant="text" @click="toggleGroup(item)">
          </VBtn>
          {{ capitalize(item.value) }}
        </td>
      </tr>
    </template>

    <template v-slot:[`item.name`]="{ value }">
      <v-chip :color="`primary`" label size="small">
        {{ value }}
      </v-chip>
    </template>

    <template v-slot:[`item.price`]="{ item }">
      <div v-if="this.returnWine(item.category)">
        <v-chip :color="`green`" label size="small" variant="outlined">
          {{ item.verre ? item.verre : '-' }}
        </v-chip>
        <v-chip :color="`green`" label size="small" variant="outlined">
          {{ item.demi ? item.demi : '-' }}
        </v-chip>
        <v-chip :color="`green`" label size="small" variant="outlined">
          {{ item.bouteille ? item.bouteille : '-' }}
        </v-chip>
      </div>
      <v-chip v-else :color="`green`" label size="small" variant="outlined">
        {{ item.price }}
      </v-chip>
    </template>

    <template v-slot:[`item.description`]="{ value }">
      <div class="description-cell">
        {{ value }}
      </div>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="actions-cell">
        <v-icon class="me-1" size="small" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon size="small" color="red" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </div>
    </template>

  </v-data-table>

  <div class="text-center pa-4">
    <v-dialog v-model="dialogDelete" max-width="400" persistent>
      <v-card prepend-icon="mdi-alert" text="Vous êtes sur le point de supprimer un élément. Continuer ?"
        title="Suppression de l'élément" color="red">
        <template v-slot:actions>
          <v-spacer></v-spacer>

          <v-btn @click="dialogDelete = false">
            Annuler
          </v-btn>

          <v-btn @click="dialogDelete = false">
            Supprimer
          </v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>

  <EditModal :open="dialogEdit" :item="editedItem" @close="dialogEdit = false" @save="saveItem" />

</template>

<script>
import { defineComponent, ref, onMounted, reactive } from 'vue'
import { db } from '@/firebase'
import { collection, onSnapshot } from 'firebase/firestore'
import EditModal from './EditModal.vue'

export default defineComponent({
  components: {
    EditModal
  },
  setup() {
    const categories = ['vinAutre', 'vinBlanc', 'vinRose', 'vinRouge', 'aperitifs', 'bieres', 'cafes', 'cocktails', 'digestifs', 'softs', 'autres', 'desserts', 'entrées', 'glaces', 'pizzas', 'pâtes', 'viandes']
    const menuItems = reactive({})
    const editedItem = ref({})
    const dialogEdit = ref(false)
    const allMenuItems = ref([])
    const dialogDelete = ref(false)
    const groupBy = ref([
      {
        key: 'category',
        order: 'asc',
      },
    ]);

    const headers = ref([
      { title: '', key: 'data-table-group', width: '0px' },
      {
        title: 'Dénomination',
        align: 'start',
        sortable: false,
        key: 'name',
      },
      { title: 'Prix', key: 'price' },
      { title: 'Description', key: 'description' },
      { title: 'Actions', align: 'end', key: 'actions', sortable: false },
    ]);

    onMounted(() => {
      categories.forEach(category => {
        const menuCollection = collection(db, category)
        onSnapshot(menuCollection, snapshot => {
          menuItems[category] = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), category: category }))
          allMenuItems.value = Object.values(menuItems).flat();
        }, error => {
          console.error(`Error fetching menu items for category ${category}:`, error)
        })
      })
    }
    )

    const returnWine = category => {
      return category === 'vinBlanc' || category === 'vinRose' || category === 'vinRouge'
    }

    const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

    const deleteItem = item => {
      console.log('Deleting item:', item)
      dialogDelete.value = true
    }

    const editItem = item => {
      editedItem.value = { ...item }
      dialogEdit.value = true
    }

    return {
      allMenuItems,
      groupBy,
      headers,
      dialogDelete,
      dialogEdit,
      editedItem,
      returnWine,
      capitalize,
      editItem,
      deleteItem
    };
  },
});
</script>

<style scoped>
.group-header {
  font-weight: bold;
  padding: 0px !important;
}

.actions-cell {
  display: flex;
  justify-content: flex-end;
}

.description-cell {
  max-width: 250px;
  /* Adjust the max-width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>