import { createRouter, createMemoryHistory } from 'vue-router';
import LoginPage from '@/components/Login.vue';
import { auth } from '@/firebase';
import NewTable from './components/AdminPage.vue';

const routes = [
  { path: '/', component: LoginPage },
  { path: '/adminPage', component: NewTable, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createMemoryHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = auth.currentUser;

  if (requiresAuth && !currentUser) {
    next('/');
  } else {
    next();
  }
});

export default router;
