<template>
  <div id="app">
    <v-app>
      <v-container fluid class="w-100">
        <v-app-bar color="teal-darken-4" elevate fixed height="100px">
          <template v-slot:image>
            <v-img gradient="to top right, rgba(211, 209, 197, .8), rgba(222, 224, 219,.8)"></v-img>
          </template>
          <v-img class="logo" :src="require('@/assets/logo.png')" max-height="100" max-width="100"></v-img>
        </v-app-bar>
        <v-main class="w-100">
          <router-view></router-view>
        </v-main>
      </v-container>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
.logo {
  margin: auto;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
